var idPerUnitChart = "savings-chart";
var idPerYearChart = "savings-peryear-chart";
var contextPerUnit = document.getElementById(idPerUnitChart).getContext("2d");
var contextPerYear = document.getElementById(idPerYearChart).getContext("2d");
var labels = ["Coût Actuel", "Coût CPO"];

var timeoutEnabled = false;
var timeout;

function initSlider(
  idSlider,
  minValue,
  maxValue,
  startingValue,
  customFormatter,
  handler
) {
  return $(idSlider)
    .slider({
      precision: 2,
      formatter: customFormatter,
      min: minValue,
      max: maxValue,
      value: startingValue
    })
    .on("change", (o, n) => {
      if (timeoutEnabled) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        clearTimeout(timeout);
        timeoutEnabled = false;
        handler();
      }, 500);
      timeoutEnabled = true;
    })
    .data("slider");
}

function moneyFormatter(value) {
  return `${value} $`;
}

function idFormatter(value) {
  return `${value}`;
}

var pSlider = initSlider(
  "#sliderPharmSalary",
  50.0,
  100.0,
  65.0,
  moneyFormatter,
  updateAllCharts
);
var aSlider = initSlider(
  "#sliderAtpSalary",
  15.0,
  30.0,
  18.0,
  moneyFormatter,
  updateAllCharts
);
var pillsSlider = initSlider(
  "#sliderPills",
  50,
  1000,
  200,
  idFormatter,
  updatePerYearChart
);

var materialCostPerHour = 0.65;
var cpoFixedCost = 7 * 0.85;

function calculatePharmcistHourRate(salary, withCpo) {
  if (withCpo) {
    return salary / (60 * 3) / 10.0;
  }

  return salary / 10.0;
}

function calculateAtpHourRate(salary, withCpo) {
  if (withCpo) {
    return salary / 60.0;
  }
  return salary / 6.0;
}

function totalCostPerHour(pSalary, aSalary, unitsCount, nbWeeks) {
  var basePrice =
    materialCostPerHour +
    calculateAtpHourRate(aSalary) +
    calculatePharmcistHourRate(pSalary);

  return basePrice * unitsCount * nbWeeks;
}

function totalCostPerHourWithCpo(pSalary, aSalary, unitsCount, nbWeeks) {
  var basePrice =
    cpoFixedCost +
    calculateAtpHourRate(aSalary, true) +
    calculatePharmcistHourRate(pSalary, true);

  return basePrice * unitsCount * nbWeeks;
}

function formatPrice(val) {
  return val.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

function displayGraph(id, ctx, data, title) {
  if (window[id] != undefined) {
    window[id].destroy();
  }

  window[id] = new Chart(ctx, {
    type: "bar",
    responsive: true,
    data: {
      labels,
      datasets: data
    },
    options: {
      legend: { display: false },
      title: {
        display: true,
        text: title
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            return "$" + formatPrice(Number(tooltipItem.yLabel));
          }
        }
      }
    }
  });
}

function buildData(cost, costWithCpo) {
  return [
    {
      label: "",
      data: [cost, costWithCpo],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255,99,132,1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1
    }
  ];
}

function updatePerUnitChart() {
  var pSalary = pSlider.getValue();
  var aSalary = aSlider.getValue();

  var costPerHour = totalCostPerHour(pSalary, aSalary, 1, 1);
  var costPerHourWithCpo = totalCostPerHourWithCpo(pSalary, aSalary, 1, 1);
  var diffPrice = costPerHour - costPerHourWithCpo;

  displayGraph(
    "perunit_graph_id",
    contextPerUnit,
    buildData(costPerHour, costPerHourWithCpo),
    "Par pilulier"
  );
  $("#unitSavings").text(formatPrice(diffPrice));
}

function updatePerYearChart() {
  var pSalary = pSlider.getValue();
  var aSalary = aSlider.getValue();
  var unitsCount = pillsSlider.getValue();

  var costPerYear = totalCostPerHour(pSalary, aSalary, unitsCount, 52);
  var costPerYearWithCpo = totalCostPerHourWithCpo(
    pSalary,
    aSalary,
    unitsCount,
    52
  );
  var diffPrice = costPerYear - costPerYearWithCpo;

  displayGraph(
    "peryear_graph_id",
    contextPerYear,
    buildData(costPerYear, costPerYearWithCpo, unitsCount, 52),
    "Par année"
  );
  $("#yearSavings").text(formatPrice(diffPrice));
}

function updateAllCharts() {
  updatePerUnitChart();
  updatePerYearChart();
}

var inViewPerUnit = false;
var inViewPerYear = false;

function isScrolledIntoView(elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = $("#" + elem).offset().top;
  var elemBottom = elemTop + $("#" + elem).height();

  return elemTop <= docViewBottom && elemBottom >= docViewTop;
}

$(window).scroll(function() {
  if (isScrolledIntoView(idPerUnitChart)) {
    if (inViewPerUnit) {
      return;
    }
    inViewPerUnit = true;
    updatePerUnitChart();
  } else {
    inViewPerUnit = false;
  }
});

$(window).scroll(function() {
  if (isScrolledIntoView(idPerYearChart)) {
    if (inViewPerYear) {
      return;
    }
    inViewPerYear = true;
    updatePerYearChart();
  } else {
    inViewPerYear = false;
  }
});
